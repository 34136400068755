import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Grid, Typography, Box, Container, Paper } from "@material-ui/core"
// import Accordion from "@material-ui/core/Accordion"
// import AccordionDetails from "@material-ui/core/AccordionDetails"
// import AccordionSummary from "@material-ui/core/AccordionSummary"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ReactMarkdown from "react-markdown"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"
import rehypeRaw from "rehype-raw"

import Layout from "../components/layout"
import Heading from "../components/heading"
import passion from "../images/careers/career-passion.jpg"

const ExpansionPanel = withStyles(theme => ({
  root: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  expanded: {},
}))(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    color: "black",
  },
}))(MuiExpansionPanelDetails)

const Careers = () => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Layout siteTitle="Contact">
      <Heading title="Careers" content="Lead the change! Be the inspiration!" />

      <Box py={5}>
        <Container maxWidth="lg">
          <Typography variant="h2" gutterBottom align="center">
            <b>
              <Box component="span" color="primary.main">
                Lead.
              </Box>{" "}
              Inspire.{" "}
              <Box component="span" color="primary.main">
                Transform.
              </Box>
            </b>
          </Typography>
        </Container>
      </Box>
      <Box pb={5}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={9}>
              <Box>
                <img src={passion} width="100%" />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box py={5} className={classes.adjust}>
                <Box color="#fff" bgcolor="rgba(20, 28, 32, .7)" p={5}>
                  <Typography variant="h5">
                    You can lead the change, be the inspiration! You can
                    transform businesses with the power of Analytics and
                    Artificial Intelligence. Do you think that challenges drive
                    you. Then you bet we’d like to hear from you.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box py={5} display="flex" flexDirection="column" alignItems="center">
        <StaticQuery
          query={graphql`
            query {
              allStrapiCareer(filter: { open: { eq: true } }) {
                edges {
                  node {
                    title
                    description
                    date
                  }
                }
              }
            }
          `}
          render={data => {
            if (data.allStrapiCareer.edges.length === 0) return <Box></Box>
            return (
              <>
                {data.allStrapiCareer.edges.map((item, key) => {
                  return (
                    <>
                      <Box py={2}>
                        <Typography variant="h4" align="center" color="primary">
                          <Box style={{ fontVariant: "small-caps" }}>
                            <b>Job Openings</b>
                          </Box>
                        </Typography>
                      </Box>
                      <Box width="75%" py={1}>
                        <Paper elevation={1}>
                          <ExpansionPanel
                            expanded={expanded === key}
                            onChange={handleChange(key)}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon color="primary" />}
                            >
                              <Box>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle2"
                                    color="primary"
                                  >
                                    <b>{item.node.title}</b>
                                  </Typography>
                                  <Box pl={1}>
                                    <Chip
                                      size="small"
                                      label="Open"
                                      style={{
                                        backgroundColor: "forestgreen",
                                        color: "white",
                                      }}
                                      deleteIcon={<DoneIcon />}
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  <b>Posted on : {item.node.date}</b>
                                </Typography>
                              </Box>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <div>
                                <ReactMarkdown
                                  rehypePlugins={[rehypeRaw]}
                                  children={item.node.description}
                                  className="dynamicContent"
                                  escapeHtml={false}
                                  color="textprimary"
                                />
                              </div>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Paper>
                      </Box>
                    </>
                  )
                })}
              </>
            )
          }}
        />
      </Box>
      <Box pt={5} pb={10}>
        <Container maxWidth="lg">
          <Box pb={2}>
            <Typography align="center" variant="h4">
              <b>Write to us</b>
            </Typography>
          </Box>
          <Typography align="center" variant="h6">
            careers@phaidelta.com
          </Typography>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  adjust: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "-480px",
    },
  },
}))

export default Careers
